<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >新增会员卡</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vip' }">会员卡</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form"  :rules="rules"  label-width="120px" size="mini" >

                <el-form-item label="名称：" prop="name">
                    <el-input style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="简介：" prop="intro">
                    <el-input style="width: 200px" v-model="form.intro"></el-input>
                </el-form-item>

                <el-form-item label="会员卡类型：">
                    <el-select style="width: 200px" v-model="form.type" placeholder="选择会员卡类型" @change="selectType">
                        <el-option label="课程折扣卡" :value="1"></el-option>
                        <el-option label="课程免费卡" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="折扣值：" v-if="this.form.type === 1">
                    <el-input style="width: 200px" v-model="form.discount" placeholder="折扣值（1-99）,90代表9折"></el-input>
                </el-form-item>


                <el-form-item label="会员卡选项：" v-for="(period,index) in form.periods" :key="index" prop="periods">
                    <el-input style="width: 150px;margin-right:10px" v-model="period.name" placeholder="选项名:例如 月卡" clearable></el-input>
                    <el-input style="width: 120px;margin-right:10px" v-model="period.indate" placeholder="时长(天)" clearable></el-input>
                    <el-input style="width: 120px;margin-right:10px" v-model="period.amount" placeholder="价格(元)" clearable></el-input>
                    <el-input style="width: 120px;margin-right:10px" v-model="period.real_amount" placeholder="划线价格" clearable></el-input>
                    <el-input style="width: 80px;margin-right:10px" v-model="period.sort" placeholder="排序" clearable></el-input>
                    <el-button  size="mini" icon="el-icon-remove-outline" @click="removeVipPeriods(period)" v-if="form.periods.length > 1">删除</el-button>
                </el-form-item>

                <el-form-item >
                    <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="addVipPeriods()">新增选项</el-button>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/fileDownload" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    export default {
        name: "template-add",
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                uploadPath:'static',
                form: {
                    name:'',
                    intro:'',
                    status:1,
                    pic:'/static/vip.png',
                    type:1,
                    discount:'',
                    periods:[
                        {
                            name:'',
                            indate:'',
                            real_amount:'',
                            amount:'',
                            sort:1
                        }
                    ]
                },
                rules: {
                    name: [
                        { required: true, message: '请输入会员卡名称', trigger: 'blur' },
                    ],
                    intro: [
                        { required: true, message: '请输入简介', trigger: 'blur' }
                    ],
                    periods: [
                        { type: 'array', required: true, message: '请输入会员卡选项', trigger: 'blur' }
                    ],
                }

            }
        },
        methods: {
            ...mapActions('marketing',['createdVipCard']),
            uploadSuccess(path){
                this.form.pic = path
            },
            //添加会员卡选项
            addVipPeriods(){
                let sort = this.form.periods.slice(-1)
                if(sort.length === 0)
                {
                    sort = 1
                }else{
                    sort = sort[0].sort + 1
                }
                this.form.periods.push({
                    name:'',
                    indate:'',
                    real_amount:'',
                    amount:'',
                    sort:sort
                });
                console.log(sort)
            },
            //删除会员卡选项
            removeVipPeriods(period){
                var index = this.form.periods.indexOf(period)
                if (index !== -1) {
                    this.form.periods.splice(index, 1)
                }
            },
            async onSubmit() {

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }
                console.log(this.form)

                this.loading = true
                this.createdVipCard(this.form).then(res => {
                    this.$message.success('新增会员卡成功')

                    this.loading = false

                    this.$router.push('/vip')
                })



            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            validateForm(formName){
                console.log(formName)
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        }
    }
</script>

<style scoped>

</style>
